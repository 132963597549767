import React from "react";
import ReportByStatus from "../../components/common/ReportByStatus";

function BonusReport({ view_user }) {
  return (
    <div>
      <p className="pageHeading mb-20">Bonus Report</p>

      <ReportByStatus status="BONUS" view_user={view_user} />
    </div>
  );
}

export default BonusReport;
