import React from "react";
import StarClassicChallengeByStatus from "../../components/common/StarClassicChallengeByStatus";

function StarClassicDropChallenge() {
  return (
    <div>
      <p className="pageHeading">Drop Challenge</p>

      <StarClassicChallengeByStatus status="DROP" />
    </div>
  );
}

export default StarClassicDropChallenge;
