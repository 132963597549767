import React from "react";
import StarClassicChallengeByStatus from "../../components/common/StarClassicChallengeByStatus";

function StarClassicPlayingChallenge() {
  return (
    <div>
      <p className="pageHeading">Running Challenge</p>

      <StarClassicChallengeByStatus status="PLAYING" />
    </div>
  );
}

export default StarClassicPlayingChallenge;
