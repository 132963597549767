import { Button, Col, Form, Input, Row, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import API_MANAGER from "../../API";

function Settings() {
  const [form] = Form.useForm();
  const [data, setData] = useState({});
  const getSettings = async () => {
    try {
      const response = await API_MANAGER.getAllSettings();
      setData(response?.data?.data);
      form.setFieldsValue({
        withdrawaltype: response?.data?.data?.withdrawaltype,
        depositType: response?.data?.data?.depositType,
        isUPIGateway: response?.data?.data?.isUPIGateway,
        isRoomCodeByPass: response?.data?.data?.isRoomCodeByPass,
        isKVMPay: response?.data?.data?.isKVMPay,
        isMSPay: response?.data?.data?.isMSPay,
        isTMPay: response?.data?.data?.isTMPay,
        isDeposit: response?.data?.data?.isDeposit,
        depositErrorMsg: response?.data?.data?.depositErrorMsg,
        createBattelMsg: response?.data?.data?.createBattelMsg,
        withdrawMsg: response?.data?.data?.withdrawMsg,
        addCashMsg: response?.data?.data?.addCashMsg,
        homePageMsg: response?.data?.data?.homePageMsg,
        allActionClose: response?.data?.data?.allActionClose,
        isReferralOff: response?.data?.data?.isReferralOff,
        manualWithdrawalAMount: response?.data?.data?.manualWithdrawalAMount,
      });
    } catch (error) {}
  };
  const handleUpadte = async (values) => {
    try {
      await API_MANAGER.updateAllSettings(data?._id, values);
      message.success("Settings updates successfully.");
    } catch (error) {
      message.error("Something went wrong.");
    }
  };
  useEffect(() => {
    getSettings();
  }, []);
  return (
    <div>
      <p className="pageHeading">Settings</p>
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        onFinish={handleUpadte}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              name={"withdrawaltype"}
              label="Withdrawal Type"
              rules={[
                { required: true, message: "Please select withdrawal type" },
              ]}
            >
              <Select
                placeholder="Select withdrawal type"
                options={[
                  { label: "Manual", value: "manual" },
                  { label: "My Pay Auto Payout", value: "autopayout" },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={"depositType"}
              label="Deposit Type"
              rules={[
                { required: true, message: "Please select deposit type" },
              ]}
            >
              <Select
                placeholder="Select deposit type"
                options={[
                  { label: "KVM Pay", value: "kvm" },
                  { label: "Phonepe Business", value: "ppb" },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={"isDeposit"}
              label="Deposit"
              rules={[
                { required: true, message: "Please select deposit type" },
              ]}
            >
              <Select
                placeholder="Select deposit type"
                options={[
                  { label: "On", value: true },
                  { label: "Off", value: false },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={"isKVMPay"}
              label="KVM Pay"
              rules={[
                { required: true, message: "Please select deposit type" },
              ]}
            >
              <Select
                placeholder="Select deposit type"
                options={[
                  { label: "On", value: true },
                  { label: "Off", value: false },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={"isMSPay"}
              label="MS Pay"
              rules={[
                { required: true, message: "Please select deposit type" },
              ]}
            >
              <Select
                placeholder="Select deposit type"
                options={[
                  { label: "On", value: true },
                  { label: "Off", value: false },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={"isTMPay"}
              label="TM Pay"
              rules={[
                { required: true, message: "Please select deposit type" },
              ]}
            >
              <Select
                placeholder="Select deposit type"
                options={[
                  { label: "On", value: true },
                  { label: "Off", value: false },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={"isUPIGateway"}
              label="UPI Gateway"
              rules={[
                { required: true, message: "Please select deposit type" },
              ]}
            >
              <Select
                placeholder="Select deposit type"
                options={[
                  { label: "On", value: true },
                  { label: "Off", value: false },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={"isRoomCodeByPass"}
              label="Roomcode Bypass"
              rules={[
                { required: true, message: "Please select roomcode bypass" },
              ]}
            >
              <Select
                placeholder="Select deposit type"
                options={[
                  { label: "On", value: true },
                  { label: "Off", value: false },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={"allActionClose"}
              label="All Action Off"
              rules={[{ required: true, message: "Please select" }]}
            >
              <Select
                placeholder="Select"
                options={[
                  { label: "On", value: true },
                  { label: "Off", value: false },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={"isReferralOff"}
              label="Referral"
              rules={[{ required: true, message: "Please select" }]}
            >
              <Select
                placeholder="Select"
                options={[
                  { label: "On", value: true },
                  { label: "Off", value: false },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={"manualWithdrawalAMount"}
              label="Manual Withdrawal Amount"
              rules={[
                { required: true, message: "Enter manual withdrawal amount" },
              ]}
            >
              <Input
                type="number"
                placeholder="Enter manual withdrawal amount"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name={"depositErrorMsg"} label="Deposit Error Message">
              <Input placeholder="Enter deposit error message" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name={"homePageMsg"} label="Home Page Message">
              <Input placeholder="Enter home page  message" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={"createBattelMsg"}
              label="Create Battel Page Message"
            >
              <Input placeholder="Enter deposit error message" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name={"withdrawMsg"} label="Withdraw Page Message">
              <Input placeholder="Enter deposit error message" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name={"addCashMsg"} label="Deposit Page Message">
              <Input placeholder="Enter deposit error message" />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <Button htmlType="submit" className="secondary_button">
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default Settings;
