import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Table,
  Input,
  Button,
  Dropdown,
  message,
  Modal,
  Form,
} from "antd";
import infoIcon from "../../Assets/infoIcon.svg";
import API_MANAGER from "../../API";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import CustomPaginationWithPageSize from "../../components/common/CustomPaginationWithPageSize";

function ManualWithdrawalRequest({ view_user }) {
  const [page, setPage] = useState({ page: 1, limit: 20 });
  const [data, setData] = useState();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [withdrawTxnIdSuccess, setWithdrawTxnIdSuccess] = useState();
  const getData = async () => {
    try {
      setLoading(true);
      const response = await API_MANAGER.getWithdrawRequest({
        ...page,
        Req_type: "MANUALWITHDRAW",
        status: "Pending",
      });
      setData(response?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const withdrawPas2 = async (id, data) => {
    const confirm = window.confirm(
      "Are you sure, you want to update to success this payout?"
    );
    if (confirm) {
      try {
        const response = await API_MANAGER.withdrawUpdate(
          {
            status: "SUCCESS",
            remark: data?.remark,
          },
          id
        );
        setWithdrawTxnIdSuccess(null);
        message.success("Updated Successfully!");
        getData();
      } catch (error) {
        setWithdrawTxnIdSuccess(null);
      }
    }
  };

  const withdrawFail = async (id) => {
    const confirm = window.confirm(
      "Are you sure, you want to update to failed this payout?"
    );
    if (confirm) {
      try {
        const response = await API_MANAGER.withdrawUpdate(
          {
            status: "FAILED",
          },
          id
        );
        message.success("Updated Successfully!");

        getData();
      } catch (error) {}
    }
  };

  useEffect(() => {
    getData();
  }, [page]);

  const handleItems = (row) => {
    return [
      {
        key: "1",
        label: (
          <div className="action-dropdown">
            {row?.status !== "SUCCESS" &&
              row?.status !== "FAILED" &&
              row?.status !== "reject" && (
                <Row>
                  <Button
                    className="item"
                    span={24}
                    onClick={() => setWithdrawTxnIdSuccess(row?.txn_id)}
                  >
                    <span>Approve By Admin</span>
                  </Button>
                </Row>
              )}
            {row?.status !== "SUCCESS" &&
              row?.status !== "FAILED" &&
              row?.status !== "reject" && (
                <Row>
                  <Button
                    className="item"
                    span={24}
                    onClick={() => withdrawFail(row?.txn_id)}
                  >
                    <span>Reject By Admin</span>
                  </Button>
                </Row>
              )}
          </div>
        ),
      },
    ];
  };
  const columns = [
    {
      title: "No.",
      dataIndex: "si",
      width: "80px",
      render: (_, row, index) => {
        return (
          <span className="cursor-pointer">
            {(page?.page - 1) * page?.limit + (index + 1)}
          </span>
        );
      },
    },
    // {
    //   title: "ID",
    //   dataIndex: "_id",
    //   key: "id",
    // },
    {
      title: "Phone",
      dataIndex: "Phone",
      key: "Phone",
      render: (item, row) => (
        <span>{view_user ? row?.user?.Phone : "N/A"}</span>
      ),
    },
    {
      title: "User",
      dataIndex: "User",
      key: "user",
      render: (item, row) => (
        <span
          className={view_user ? "tableLink" : ""}
          onClick={() => {
            if (view_user) navigate(`/user/view/${row?.user?._id}`);
          }}
        >
          {row?.user ? row?.user?.Name : ""}
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (item) => <span> {item ? item : "Proccessing"}</span>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Order ID",
      dataIndex: "UTR_number",
      key: "UTR_number",
    },
    {
      title: "UPI ID",
      dataIndex: "upiID",
      key: "upiID",
      render: (item, row) => <span>{row?.user?.upi_id}</span>,
    },
    {
      title: "Account No.",
      dataIndex: "account_number",
      key: "account_number",
      render: (item, row) => <span>{row?.user?.account_number}</span>,
    },
    {
      title: "Bank",
      dataIndex: "bank_name",
      key: "bank_name",
      render: (item, row) => <span>{row?.user?.bank_name}</span>,
    },
    {
      title: "IFSC",
      dataIndex: "ifsc_code",
      key: "ifsc_code",
      render: (item, row) => <span>{row?.user?.ifsc_code}</span>,
    },
    {
      title: "Account Holder",
      dataIndex: "holder_name",
      key: "holder_name",
      render: (item, row) => <span>{row?.user?.holder_name}</span>,
    },
    {
      title: "Withdraw Type",
      dataIndex: "type",
      key: "type    ",
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (item) => <span>{moment(item).format("LLL")}</span>,
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      render: (item, row) => (
        <Row align={"middle"}>
          <Dropdown
            placement="bottom"
            overlayClassName="action-dropdown"
            menu={{
              items: handleItems(row),
            }}
            trigger={"click"}
          >
            <img
              onClick={(e) => {
                e.preventDefault();
                // setRowData(row);
              }}
              className="cursor_pointer"
              src={infoIcon}
              alt="edit"
            />
          </Dropdown>
        </Row>
      ),
    },
  ];
  return (
    <div className="deposit_history">
      <Row className="pageHeading mb-20">Manual Withdrawal Request</Row>

      <Table
        columns={columns}
        dataSource={data?.result ? data?.result : []}
        pagination={false}
        className="table"
        rowKey={"id"}
        loading={loading}
        style={{ marginTop: "24px" }}
        scroll={{
          // y: "calc(100vh - 400px)",
          x: "calc(768px)",
        }}
      />
      <CustomPaginationWithPageSize
        currentPage={page}
        setCurrentPage={setPage}
        total={data?.totalCount}
      />
      <Modal
        centered
        open={withdrawTxnIdSuccess}
        closable={true}
        footer={false}
        onCancel={() => setWithdrawTxnIdSuccess(null)}
        // onOk={() => withdrawPas2(withdrawTxnIdSuccess)}
      >
        <Form
          layout="vertical"
          requiredMark={false}
          onFinish={(data) => withdrawPas2(withdrawTxnIdSuccess, data)}
        >
          <Form.Item
            name={"remark"}
            label="Remark*"
            rules={[{ required: true, message: "Please enter remark." }]}
          >
            <Input placeholder="Enter remark" />
          </Form.Item>
          <Row justify={"end"}>
            <Col>
              <Button htmlType="submit">Submit</Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
}

export default ManualWithdrawalRequest;
