import React from "react";
import StarClassicChallengeByStatus from "../../components/common/StarClassicChallengeByStatus";

function StarClassicCompletedChallenge() {
  return (
    <div>
      <p className="pageHeading">Completed Challenge</p>

      <StarClassicChallengeByStatus status="COMPLETED" />
    </div>
  );
}

export default StarClassicCompletedChallenge;
