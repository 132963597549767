import React, { useEffect, useState } from "react";
import ChallengeByStatus from "../../components/common/ChallengeByStatus";
import { Button, Col, Form, Input, message, Modal, Row, Table } from "antd";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import API_MANAGER from "../../API";

function StarClassicChallenge() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [rowData, setRowData] = useState();
  const navigate = useNavigate();
  const getAllGames = async () => {
    try {
      const response = await API_MANAGER.getAllStarLudoClassicGames();
      setData(response?.data?.data);
      console.log(response.data?.data, "jhjhjjj");
    } catch (e) {
      message.error("Something went wrong.");
    }
  };
  const handleDelete = async (data) => {
    try {
      const confirm = window.confirm("are you sure to delete?");

      if (confirm) {
        try {
          const response = await API_MANAGER.deleteStarClassicGame({
            id: data?._id,
          });
          message.success("deleted Successfully");
          getAllGames();
        } catch (error) {
          message.error("Something went wrong!");
        }
      }
    } catch (error) {
      message.error("Something Went Wrong");
      console.log(error);
    }
  };
  const columns = [
    {
      title: "No.",
      dataIndex: "si",
      width: "80px",
      render: (_, row, index) => {
        return <span className="cursor-pointer">{index + 1}</span>;
      },
    },
    {
      title: "ID",
      dataIndex: "_id",
      key: "_id",
    },

    {
      title: "Amount",
      dataIndex: "Game_Ammount",
      key: "Game_Ammount",
    },
    {
      title: "Prize",
      dataIndex: "prize",
      key: "prize",
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, row) => {
        return (
          <span className="cursor-pointer">
            {moment(row?.createdAt).format("LLL")}
          </span>
        );
      },
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, row) => {
        return (
          <Row gutter={[8, 8]}>
            <Col>
              <Button
                className="secondary_button"
                onClick={() => setRowData(row)}
              >
                Edit
              </Button>
            </Col>
            <Col>
              <Button
                className="secondary_button"
                onClick={() => handleDelete(row)}
              >
                Delete
              </Button>
            </Col>
          </Row>
        );
      },
    },
  ];
  const handleSubmit = async (data) => {
    try {
      const response = await API_MANAGER.createStarClassicGame(data);
      message.success("Created Successfully");
    } catch (error) {
      message.error("Something Went Wrong");
      console.log(error);
    }
  };

  useEffect(() => {
    getAllGames();
  }, []);
  return (
    <div>
      <p className="pageHeading">Star Classic Challenge</p>
      <Row justify={"end"}>
        <Col>
          <Button
            onClick={() => setOpenModal(true)}
            className="secondary_button mb-20"
          >
            Create
          </Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={data || []}
        pagination={false}
        className="table"
        rowKey={"id"}
        loading={loading}
        scroll={{
          //   y: "calc(100vh - 400px)",
          x: "calc(768px)",
        }}
      />{" "}
      <Modal
        open={openModal}
        footer={false}
        onCancel={() => setOpenModal(false)}
        centered
        title="Create Game"
      >
        <Form layout="vertical" onFinish={handleSubmit}>
          <Row>
            <Col span={24}>
              <Form.Item
                name={"Game_Ammount"}
                label="Amount"
                rules={[{ required: true, message: "Please Enter Amount" }]}
              >
                <Input placeholder="Enter Amount" type="number" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name={"prize"}
                label="Prize"
                rules={[{ required: true, message: "Please Enter Prize" }]}
              >
                <Input placeholder="Enter prize" type="number" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Button htmlType="submit">Submit</Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
}

export default StarClassicChallenge;
