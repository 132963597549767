import React from "react";
import ReportByStatus from "../../components/common/ReportByStatus";

function ManualWithdrawalReport({ view_user }) {
  return (
    <div>
      <p className="pageHeading mb-20">Withdrawal Report</p>

      <ReportByStatus status="MANUALWITHDRAW" view_user={view_user} />
    </div>
  );
}

export default ManualWithdrawalReport;
