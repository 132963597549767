import React from "react";
import StarClassicChallengeByStatus from "../../components/common/StarClassicChallengeByStatus";

function StarClassicMatchedChallenge() {
  return (
    <div>
      <p className="pageHeading">Drop Challenge</p>

      <StarClassicChallengeByStatus status="MATCHED" />
    </div>
  );
}

export default StarClassicMatchedChallenge;
