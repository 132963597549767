import React, { useEffect, useState } from "react";
import API_MANAGER from "../API";
import {
  DatePicker,
  Row,
  Table,
  message,
  Col,
  Modal,
  Form,
  Select,
  Input,
  Button,
} from "antd";
import moment from "moment";
import CustomPaginationWithPageSize from "../components/common/CustomPaginationWithPageSize";

function AdminPanelty() {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [adminList, setAdminList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [page, setPage] = useState({
    page: 1,
    limit: 20,
  });
  const [data, setData] = useState([]);
  const [totalEarning, setTotalEarning] = useState(0);
  const [loading, setLoading] = useState(false);
  const getData = async () => {
    try {
      let params = {
        ...page,
      };

      setLoading(true);
      const response = await API_MANAGER.getAdminPanelty(params);
      setData(response?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      message.error(error?.response?.data?.message || "Something went wrong!");
    }
  };
  const getAdminList = async () => {
    try {
      const response = await API_MANAGER.getAllAdmin();
      setAdminList(response?.data?.data);
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong!");
    }
  };
  const createPanelty = async (data) => {
    try {
      console.log(data, "data");
      setLoading(true);
      const response = await API_MANAGER.createAdminPanelty(data);
      setLoading(false);
      setOpenModal(false);
      getData();
    } catch (error) {
      setLoading(false);

      message.error(error?.response?.data?.message || "Something went wrong!");
    }
  };
  const getTotalEarning = async () => {
    try {
      let params = {};
      if (startDate && endDate) {
        params = {
          FROM_DATE: startDate,
          TO_DATE: endDate,
        };
      }

      const response = await API_MANAGER.getTotalAdminEarning(params);
      setTotalEarning(response?.data?.data);
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong!");
    }
  };

  useEffect(() => {
    getTotalEarning();
  }, [startDate, endDate]);
  useEffect(() => {
    getData();
  }, [page, startDate, endDate]);

  useEffect(() => {
    getAdminList();
  }, []);
  const columns = [
    {
      title: "No.",
      dataIndex: "si",
      width: "80px",
      render: (_, row, index) => {
        return (
          <span className="cursor-pointer">
            {(page?.page - 1) * page?.limit + (index + 1)}
          </span>
        );
      },
    },
    {
      title: "Admin",
      dataIndex: "admin",
      key: "admin",
      render: (item) => <span>{item?.Name}</span>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
    },
    {
      title: "Panelty By",
      dataIndex: "action_by",
      key: "action_by",
      render: (item) => <span>{item?.Name}</span>,
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (item) => <span>{moment(item).format("LLL")}</span>,
    },
  ];
  return (
    <div>
      <p className="pageHeading">Admin Panelty</p>
      <Row justify={"end"}>
        <Col>
          <Button onClick={() => setOpenModal(true)}>Add Panelty</Button>
        </Col>
      </Row>
      <Modal
        open={openModal}
        footer={false}
        centered
        onCancel={() => setOpenModal(false)}
      >
        <Form layout="vertical" onFinish={createPanelty}>
          <Row>
            <Col span={24}>
              <Form.Item
                name={"admin"}
                label="Admin"
                rules={[
                  {
                    required: true,
                    message: "Please select admin",
                  },
                ]}
              >
                <Select placeholder="Select User" showSearch>
                  {adminList?.map((user) => (
                    <Select.Option value={user?._id} key={user?.id}>
                      {`${user?.Name ? user?.Name : " "} - ${user?.Phone}`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name={"reason"}
                label="Remark"
                rules={[
                  {
                    required: true,
                    message: "Please enter remark.",
                  },
                ]}
              >
                <Input placeholder="Enter reason" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name={"amount"}
                label="Panelty Type"
                rules={[
                  {
                    required: true,
                    message: "Please select panelty type.",
                  },
                ]}
              >
                <Select placeholder="Select panelty type">
                  <Select.Option value={20}>
                    {`Support (Amount Rs 20)`}
                  </Select.Option>
                  <Select.Option value={100}>
                    {`Game (Amount Rs 100)`}
                  </Select.Option>
                  <Select.Option value={100}>
                    {`Other (Amount Rs 100)`}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button htmlType="submit">Submit</Button>
          </Form.Item>
        </Form>
      </Modal>

      <div>
        <Table
          columns={columns}
          dataSource={data?.result ? data?.result : []}
          pagination={false}
          className="table"
          loading={loading}
          rowKey={"id"}
          style={{ marginTop: "24px" }}
          // scroll={{
          //   // y: "calc(100vh - 400px)",
          //   x: "calc(768px + 40%)",
          // }}
        />
        <CustomPaginationWithPageSize
          currentPage={page}
          setCurrentPage={setPage}
          total={data?.totalCount}
        />
      </div>
    </div>
  );
}

export default AdminPanelty;
