import React from "react";
import StarClassicChallengeByStatus from "../../components/common/StarClassicChallengeByStatus";

function StarClassicCancelledChallenge() {
  return (
    <div>
      <p className="pageHeading">Cancelled Challenge</p>

      <StarClassicChallengeByStatus status="CANCELLED" />
    </div>
  );
}

export default StarClassicCancelledChallenge;
