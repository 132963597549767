import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Table,
  Dropdown,
  message,
  Form,
  Select,
  Input,
  Button,
  Modal,
} from "antd";
import infoIcon from "../../Assets/infoIcon.svg";
import API_MANAGER from "../../API";
import { useNavigate } from "react-router-dom";
import CustomPaginationWithPageSize from "../../components/common/CustomPaginationWithPageSize";

function BlockedUsers({ view_user }) {
  const [page, setPage] = useState({ page: 1, limit: 20 });
  const [data, setData] = useState();
  const [blockUser, setBlockUser] = useState(null);

  const navigate = useNavigate();
  const getData = async () => {
    try {
      let params = {
        ...page,
      };

      const response = await API_MANAGER.getAllBlockUsers(params);

      setData(response?.data?.data);
    } catch (error) {
      message.error("Something went wrong!");
    }
  };
  const blockUnblockPlayer = async (player, data) => {
    const confirmBox = window.confirm(
      `are you sure you want to unblock ${player?.Name}`
    );
    if (confirmBox === true) {
      const userType = "User";

      try {
        await API_MANAGER.blockUnblockPlayer({
          _id: player?._id,
          user_type: userType,
          remark: data?.remark,
        });
        message.success("Updated successfully!");
        setBlockUser(null);

        getData();
      } catch (error) {
        setBlockUser(null);

        message.error("Something went wrong!");
      }
    }
  };

  const handleItems = (row) => {
    return [
      {
        key: "1",
        label: (
          <div className="action-dropdown">
            {/* <Row>
              <Col
                className="item"
                span={24}
                onClick={() => navigate(`/user/view/${row?._id}`)}
              >
                <span>View</span>
              </Col>
            </Row> */}

            <Row>
              <Col
                onClick={() => {
                  setBlockUser(row);
                }}
                className="item"
                span={24}
              >
                <span>{row?.user_type == "Block" ? "Unblock" : "Block"}</span>
              </Col>
            </Row>

            {/* <Row>
              <Col
                className="item"
                span={24}
                // onClick={() => withdrawPass(row?.txn_id)}
              >
                <span>Edit</span>
              </Col>
            </Row> */}

            {/* <Row>
              <Col className="item" span={24} onClick={() => deletePlayer(row)}>
                <span>Delete</span>
              </Col>
            </Row> */}
          </div>
        ),
      },
    ];
  };
  const columns = [
    {
      title: "No.",
      dataIndex: "si",
      width: "80px",
      render: (_, row, index) => {
        return (
          <span className="cursor-pointer">
            {(page?.page - 1) * page?.limit + (index + 1)}
          </span>
        );
      },
    },
    // {
    //   title: "ID",
    //   dataIndex: "_id",
    //   key: "id",
    // },
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
      render: (item, row) => (
        <span
          className={view_user ? "tableLink" : ""}
          onClick={() => {
            if (view_user) navigate(`/user/view/${row?._id}`);
          }}
        >
          {item}
        </span>
      ),
    },

    {
      title: "Phone",
      dataIndex: "Phone",
      key: "Phone",
      render: (item) => <span>{view_user ? item : "N/A"}</span>,
    },
    {
      title: "Balance",
      dataIndex: "Wallet_balance",
      key: "Wallet_balance",
      render: (item, row) => <span>₹{row?.Wallet_balance}</span>,
    },

    {
      title: "MissMatch",
      dataIndex: "MissMatch",
      key: "MissMatch",
      render: (item, row) => (
        <span>
          ₹
          {row?.Wallet_balance -
            (row?.wonAmount +
              row?.totalDeposit +
              row?.referral_earning +
              row?.totalBonus -
              (row?.loseAmount +
                row?.totalWithdrawl +
                row?.referral_wallet +
                row?.withdraw_holdbalance +
                row?.hold_balance +
                row?.totalPenalty))}
        </span>
      ),
    },
    {
      title: "Game hold",
      dataIndex: "hold_balance",
      key: "hold_balance",
    },
    {
      title: "Reffered By",
      dataIndex: "referral",
      key: "referral",
    },
    {
      title: "Verified",
      dataIndex: "verified",
      key: "verified",
    },
    {
      title: "Remark",
      dataIndex: "remark",
      key: "remark",
    },
    {
      title: "Action By",
      dataIndex: "action_by",
      key: "action_by",
      render: (_, row) => {
        return (
          <span className="cursor-pointer">
            {row?.action_by?.Phone}{" "}
            {row?.action_by?.Name ? `(${row?.action_by?.Name})` : ""}
          </span>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      render: (item, row) => (
        <Row align={"middle"}>
          <Dropdown
            placement="bottom"
            overlayClassName="action-dropdown"
            menu={{
              items: handleItems(row),
            }}
            trigger={"click"}
          >
            <img
              onClick={(e) => {
                e.preventDefault();
                // setRowData(row);
              }}
              className="cursor_pointer"
              src={infoIcon}
              alt="edit"
            />
          </Dropdown>
        </Row>
      ),
    },
  ];
  useEffect(() => {
    getData();
  }, [page]);
  return (
    <div>
      <p className="pageHeading">Blocked Users</p>

      <Table
        columns={columns}
        dataSource={data?.result ? data?.result : []}
        pagination={false}
        className="table"
        rowKey={"id"}
        style={{ marginTop: "24px", borderRadius: "0px" }}
        scroll={{
          // y: "calc(100vh - 400px)",
          x: "calc(767px)",
        }}
      />
      <CustomPaginationWithPageSize
        currentPage={page}
        setCurrentPage={setPage}
        total={data?.totalCount}
      />
      <Modal
        centered
        open={blockUser}
        closable={true}
        footer={false}
        onCancel={() => setBlockUser(null)}
        // onOk={() => withdrawPas2(withdrawTxnIdSuccess)}
      >
        <Form
          layout="vertical"
          requiredMark={false}
          onFinish={(data) => blockUnblockPlayer(blockUser, data)}
        >
          <Form.Item
            name={"remark"}
            label="Remark*"
            rules={[{ required: true, message: "Please enter remark." }]}
          >
            <Input placeholder="Enter remark" />
          </Form.Item>
          <Row justify={"end"}>
            <Col>
              <Button htmlType="submit">Submit</Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
}

export default BlockedUsers;
