import React from "react";
import StarClassicChallengeByStatus from "../../components/common/StarClassicChallengeByStatus";

function StarClassicWaitingChallenge() {
  return (
    <div>
      <p className="pageHeading">Waiting Challenge</p>

      <StarClassicChallengeByStatus status="WAITING" />
    </div>
  );
}

export default StarClassicWaitingChallenge;
